<template>
  <teleport to="body">
    <div class="mask"></div>
    <div
      class="mask-step5"
      :style="{
        width: w + 'px',
        height: h + 'px',
        top: top + 'px',
        left: left + 'px',
      }"
    >
      <div class="step5-content1">
        <div class="fs-16 fw-7">批量编辑联系人信息</div>
        <div class="fs-14 mt-10">
          批量勾选您需要修改信息的联系人，点击编辑，选择需要修改的字段后可一键批量修改。
        </div>
        <div class="flex mt-10">
          <span>5 / 5</span>
          <div class="flex">
            <div
              class="l-btn-primary mr-10"
              style="
                font-size: 12px;
                padding: 5px 10px;
                line-height: 1.5;
                background: #fff;
                color: #000;
                border: 1px solid #848484;
              "
              @click="stepChange('prev')"
            >
              上一步
            </div>
            <div
              class="l-btn-primary"
              style="font-size: 12px; padding: 5px 10px; line-height: 1.5"
              @click="stepChange('done')"
            >
              完成
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { reactive, onMounted, nextTick, toRefs } from 'vue';
export default {
  name: 'step5',
  emits: ['stepChange'],
  setup(props, context) {
    const maskRect = reactive({
      w: 0,
      h: 0,
      top: 0,
      left: 0,
    });
    onMounted(() => {
      nextTick(() => {
        const step5 = document.getElementById('step5');
        const rect = step5.getBoundingClientRect();
        maskRect.w = rect.width + 10;
        maskRect.h = rect.height + 10;
        maskRect.top = rect.y - 5;
        maskRect.left = rect.x - 5;
      });
    });
    const stepChange = type => {
      context.emit('stepChange', type, 5);
    };
    return {
      ...toRefs(maskRect),
      stepChange,
    };
  },
};
</script>

<style lang="less" scoped>
.mask-step5 {
  position: fixed;
  width: 170px;
  height: 190px;
  top: 260px;
  left: 208px;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.6) 0 0 0 9999px;
  & > div {
    border-radius: 4px;
    padding: 16px;
    background-color: #fff;
  }
}
.step5-content1 {
  width: 400px;
  position: absolute;
  top: -175px;
  left: -155px;

  &::before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 50%;
    z-index: 1000;
    margin-left: -10px; /* 将箭头置于对话框的中央 */
    border-width: 10px;
    border-style: solid;
    border-color: #ffffff transparent transparent transparent; /* 设置箭头颜色 */
  }
}
</style>
