<template>
  <teleport to="body">
    <div class="mask"></div>
    <div
      class="mask-step3"
      :style="{
        width: w + 'px',
        height: h + 'px',
        top: top + 'px',
        left: left + 'px',
      }"
    >
      <div class="step3-content1">
        <div class="fs-16 fw-7">批量分关系组</div>
        <div class="fs-14 mt-10">
          批量勾选您需要分组的联系人，一键添加关系组高效整理联系人。
        </div>
        <div class="flex mt-10">
          <span>3 / 5</span>
          <div class="flex">
            <div
              class="l-btn-primary mr-10"
              style="
                font-size: 12px;
                padding: 5px 10px;
                line-height: 1.5;
                background: #fff;
                color: #000;
                border: 1px solid #848484;
              "
              @click="stepChange('prev')"
            >
              上一步
            </div>
            <div
              class="l-btn-primary"
              style="font-size: 12px; padding: 5px 10px; line-height: 1.5"
              @click="stepChange('next')"
            >
              下一步
            </div>
          </div>
        </div>
      </div>
      <div
        class="arrow-content"
        :style="{
          padding: '0px',
          width: lineStyle.w + 'px',
          height: lineStyle.h + 'px',
          top: lineStyle.top + 'px',
          left: lineStyle.left + 'px',
        }"
      ></div>
      <div
        class="step3-content2"
        :style="{
          padding: '0px 8px',
          width: maskRect1.w + 'px',
          height: maskRect1.h + 'px',
          top: maskRect1.top + 'px',
          left: maskRect1.left + 'px',
        }"
      >
        <img src="@/assets/images/step/step3.jpg" style="width: 93%" />
      </div>
    </div>
  </teleport>
</template>

<script>
import { reactive, onMounted, nextTick, toRefs } from 'vue';
export default {
  name: 'step3',
  emits: ['stepChange'],
  setup(props, context) {
    const maskRect = reactive({
      w: 0,
      h: 0,
      top: 0,
      left: 0,
    });
    const maskRect1 = reactive({
      w: 0,
      h: 0,
      top: 0,
      left: 0,
    });
    const lineStyle = reactive({
      w: 0,
      h: 1,
      top: 10,
      left: 10,
    });
    onMounted(() => {
      nextTick(() => {
        const step3 = document.getElementById('step3');
        const rect = step3.getBoundingClientRect();
        maskRect.w = rect.width + 10;
        maskRect.h = rect.height + 10;
        maskRect.top = rect.y - 5;
        maskRect.left = rect.x - 5;
        const step33 = document.getElementsByClassName('step33')[0];
        const rect33 = step33.getBoundingClientRect();
        maskRect1.w = rect33.width;
        maskRect1.h = rect33.height;
        maskRect1.top = rect33.y;
        maskRect1.left = rect33.x;
        lineStyle.w = rect.x - 100;
        lineStyle.h = rect33.y - rect.y + 60;
        lineStyle.top = rect.y + rect.height - 80;
        lineStyle.left = 120;
      });
    });
    const stepChange = type => {
      context.emit('stepChange', type, 3);
    };
    return {
      lineStyle,
      maskRect1,
      ...toRefs(maskRect),
      stepChange,
    };
  },
};
</script>

<style lang="less" scoped>
.mask-step3 {
  position: fixed;
  right: 0;
  width: 50%;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.6) 0 0 0 9999px;
  & > div {
    border-radius: 4px;
    padding: 16px;
    background-color: #fff;
  }
}

.step3-content1 {
  width: 400px;
  position: absolute;
  top: -175px;
  left: -155px;

  &::before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 50%;
    z-index: 1000;
    margin-left: -10px; /* 将箭头置于对话框的中央 */
    border-width: 10px;
    border-style: solid;
    border-color: #ffffff transparent transparent transparent; /* 设置箭头颜色 */
  }
}
.step3-content2 {
  padding: 0 !important;
  position: fixed;
  display: flex;
  align-items: center;
}
.arrow-content {
  background-color: transparent !important;
  position: fixed;
  background: url('../../../../assets/images/step/line.png') no-repeat center
    center;
  background-size: 100% 100%;
}
</style>
