<template>
  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <div class="title">
        <div class="contentTitle">
          <div class="contentOne">全部联系人</div>
          <div class="contentTwo"></div>
        </div>
        <div class="contentAdd" @click="handleAdd">创建联系人</div>
      </div>

      <div class="mt-40">
        <contact-grid
          ref="gridRef"
          allow-selection
          :showHeader="true"
          :columns="columns"
          :code="$route.path"
          :url="url"
          :items="items"
          :btn-operation="btnOperation"
          :url-params="parmas"
          :scroll="{ x: 1200, y: gridHeight }"
          @dataChange="dataChange"
          @fnGrid="handleGrid"
          @searchVal='getSearch'
        >
          <template #operation="{ record }">
            <operation :options="options" :record="record" />
          </template>
          <template v-slot:name="{ record }">
            <div class="cursor-p" @click="handleGoToContact(record)">
              {{ record.name }}
            </div>
          </template>
          <template v-slot:avatar="{ record }">
            <a-avatar
              v-if="record.avatar"
              :src="setAvatar(record.avatar)"
              @click="handleGoToContact(record)"
              class="headerUrl"
            />
            <div v-else @click="handleGoToContact(record)" class="headerText">
              <div v-if="record.name">
                {{ record.name[0] }}
              </div>
            </div>
          </template>

          <template #frequency="{ record }">
            <!-- <div v-for="item in frequenctList" :key="item.id">
              <div
                v-if="item.itemCode === record.frequency"
                @click="handleOpenPinci(item)"
              >
                <img
                  src="@/assets/images/box/woshou.png"
                  alt=""
                  class="frequencyImage"
                />
                {{ item.itemNameEn) }}
              </div>
            </div> -->
            <div class="frequenct-item" @click="handleOpenPinci(record)">
              <img
                src="@/assets/images/box/woshou.png"
                alt=""
                class="frequencyImage"
              />
              {{ handlePinCi(record.frequency) }}
            </div>
          </template>

          <template #infoList="{ record }">
            <div class="recordContent">
              <div v-if="record.douYinUrl">
                <img
                  src="@/assets/images/box/dou.png"
                  alt=""
                  class="dou-img"
                  @click="handleLine(record.douYinUrl)"
                />
              </div>
              <div v-if="record.weiboUrl">
                <img
                  src="@/assets/images/box/wei.png"
                  alt=""
                  class="wei-img"
                  @click="handleLine(record.weiboUrl)"
                />
              </div>
              <div v-if="record.zhiHuUrl">
                <img
                  src="@/assets/images/box/zhi.png"
                  alt=""
                  class="zhi-img"
                  @click="handleLine(record.zhiHuUrl)"
                />
              </div>

              <div
                v-if="
                  record.infoList.every(
                    item => item.email === '' || item.email === null
                  )
                "
              >
                <div class="contact-icon" style="margin-right: 10px">
                  <img
                    src="@/assets/images/box/youhui.png"
                    alt=""
                    class="youhui-img"
                  />
                </div>
              </div>
              <a-dropdown placement="bottomCenter" v-else>
                <div class="contact-icon">
                  <img
                    src="@/assets/images/box/you.png"
                    alt=""
                    class="activeColor email"
                  />
                </div>

                <template #overlay>
                  <!--              cricleList  v-if="item.email === null || item.email === ''" -->
                  <a-menu
                    :class="{
                      dropdownCalss: record.infoList.some(
                        item => item.email === null || item.email === ''
                      ),
                    }"
                  >
                    <a-menu-item
                      v-for="item in record.infoList"
                      :key="item.id"
                      class="menuClass"
                      :class="{
                        nonePhone: item.email === '' || item.email === null,
                      }"
                    >
                      {{ item.email }}
                      <div
                        class="menuText activeColor"
                        v-if="item.email"
                        @click="getEmail(item)"
                      >
                        复制
                      </div>
                    </a-menu-item>

                    <div
                      v-if="
                        record.infoList.length === 0 ||
                        record.infoList.some(
                          item => item.email !== null || item.email === ''
                        )
                      "
                      @click="getAllEmail(record.infoList)"
                      class="allValue activeColor"
                    >
                      复制全部
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>

              <div
                v-if="
                  record.infoList.every(
                    item => item.phone === '' || item.phone === null
                  )
                "
              >
                <div class="contact-icon" style="margin-right: 10px">
                  <img
                    src="@/assets/images/box/dianhui.png"
                    alt=""
                    class="dianhui-img"
                  />
                </div>
              </div>
              <!--          电话   -->
              <a-dropdown placement="bottomCenter" v-else>
                <!--                <component :is="icons['PhoneOutlined']" class="activeColor"/>-->
                <div class="contact-icon">
                  <img
                    src="@/assets/images/box/dian.png"
                    alt=""
                    class="activeColor cursor-p"
                  />
                </div>

                <template #overlay>
                  <!--              cricleList   -->
                  <a-menu
                    @click="handleClickByMenu"
                    :class="{
                      dropdownCalss: record.infoList.some(
                        item => item.phone === null || item.phone === ''
                      ),
                    }"
                  >
                    <a-menu-item
                      v-for="item in record.infoList"
                      :key="item.id"
                      class="menuClass"
                      :class="{
                        nonePhone: item.phone === '' || item.phone === null,
                      }"
                    >
                      {{ item.phone }}
                      <div
                        class="menuText activeColor"
                        v-if="item.phone"
                        @click="getValue(item)"
                      >
                        复制
                      </div>
                    </a-menu-item>
                    <div
                      @click="getAllValue(record.infoList)"
                      class="allValue activeColor"
                      v-if="
                        record.infoList.length === 0 ||
                        record.infoList.some(
                          item => item.phone !== null || item.phone === ''
                        )
                      "
                    >
                      复制全部
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </template>

          <template v-slot:lastInteractionTime="{ record }">
            <div>
              {{ handleTime(record) }}
            </div>
          </template>
        </contact-grid>
      </div>
    </div>
  </div>

  <iss-add-page
    v-model:visible="visibleAddPage"
    :activeItem="activeItem"
    :jump="true"
    @fnOk="handleAddPage"
  />

  <iss-frequency
    v-model:visible="visibleFrequency"
    :init="activeItemFrequency"
    @fnOk="handleFrequencyPage"
  />

  <iss-add-group
    v-model:visible="visibleAddGroup"
    :init="activeItemAddGroup"
    @fnOk="handleAddGroup"
  />
  <iss-pulse
    v-model:visible="visiblePluse"
    :init="activeItemPluse"
    @fnOk="handlePluse"
  />

  <iss-edit
    v-model:visible="visibleEdit"
    :init="activeItemEdit"
    @fnOk="handleEditPage"
  />
  <a-modal
    v-model:visible="visible"
    :footer="null"
    :width="700"
    :bodyStyle="{
      background:
        'linear-gradient(360deg, #FDF6EC 0%, rgba(255,255,255,0) 64%)',
    }"
  >
    <div class="start-modal">
      <img
        class="mt-20 mb-20"
        src="@/assets/images/dashboard/successIcon.png"
        width="200"
        alt=""
      />
      <div class="l-text-primary fs-20 mb-20 fw-7">
        恭喜您，完成联系人数据清洗！
      </div>
      <div class="fs-16 mb-20">现在跟着新手指引快速整理您的人脉宝藏吧！</div>
      <div
        class="l-btn-primary mb-40"
        style="border-radius: 30px; font-size: 20px; padding: 8px 50px"
        @click="startHandle"
      >
        开始指引
      </div>
    </div>
  </a-modal>
  <a-modal
    v-model:visible="loadingVisible"
    :footer="null"
    :width="700"
    :maskClosable="false"
    :closable="false"
    :bodyStyle="{
      background:
        'linear-gradient(360deg, #FDF6EC 0%, rgba(255,255,255,0) 64%)',
    }"
  >
    <div class="start-modal">
      <div class="loading-content">
        <img
          src="@/assets/images/dashboard/loadingImg.png"
          alt="loading"
          style="margin-left: 30px"
        />
        <div class="loading">
          <component :is="icons['LoadingOutlined']" class="activeColor" />
          <span class="loading-text pl-20">数据加载中...</span>
        </div>
      </div>
    </div>
  </a-modal>
  <step1 v-if="step === 1" @stepChange="stepChange" />
  <step2 v-if="step === 2" @stepChange="stepChange" />
  <step3 v-if="step === 3" @stepChange="stepChange" />
  <step4 v-if="step === 4" @stepChange="stepChange" />
  <step5 v-if="step === 5" @stepChange="stepChange" />
</template>

<script>
import { reactive, ref, toRefs, getCurrentInstance, createVNode } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import IssPulse from '@/components/pulse';
import IssAddPage from '@/views/contacts/contactAall/addPage';
// import IssFrequency from '@/views/contacts/contactAall/frequency';
import IssFrequency from '@/views/today/homePage/components/frequencyTwo';
import IssAddGroup from '@/views/contacts/contactAall/addGroup';
import IssEdit from '@/views/contacts/contactAall/edit';
import contactGrid from '@/components/contactGrid';
import step1 from './components/setp1';
import step2 from './components/setp2';
import step3 from './components/setp3';
import step4 from './components/setp4';
import step5 from './components/setp5';
import dashboardApi from '@/api/dashboard.js';
import contactAll from '@/api/contactAll';
import { message, Dropdown, Menu, Avatar, Modal } from 'ant-design-vue';
import { setAvatar } from '@/utils';
import { copyText } from '@/utils';
import * as icons from '@ant-design/icons-vue';
import todayApi from '@/api/today';
import Operation from '@/components/operation';
import { isDelBoundary } from '@/utils';

export default {
  components: {
    contactGrid,
    // APopover: Popover,
    Operation,
    IssAddPage,
    // IssPulse,
    // IssGroup,
    IssFrequency,
    IssAddGroup,
    IssEdit,
    IssPulse,
    // Modal,
    // Icon: icons,
    ADropdown: Dropdown,
    AMenu: Menu,
    AAvatar: Avatar,
    // IssContactDetails,
    // ATag: Tag,
    step1,
    step2,
    step3,
    step4,
    step5,
  },
  setup() {
    const gridRef = ref();
    const step = ref(null);
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const userId = store.state.account.user.id;
    const state = reactive({
      frequenctList: [],
      parmas: {
        userId: userId,
        conditionDto: {
          sortMap: {
            field: '',
            sort: 'asc',
          },
          fieldList: [
            'company',
            'position',
            'describe',
            'frequency',
            'last_interaction_time',
            'socialize_url',
          ],
          conditionMap: [
            {
              field: '',
              relation: '',
              value: '',
            },
          ],
        },
      },
      name: '',
      searchVal: '',
      search: {},
      TagList: [],
      visible: false,
      loadingVisible: false,
      visibleAddPage: false,
      visibleFrequency: false,
      activeItemFrequency: [],
      activeItemAddGroup: [],
      activeItemPluse: [],
      activeItemEdit: [],
      visiblePluse: false,
      visibleAddGroup: false,
      visibleEdit: false,
      selectedRowKeys: [],
      dataSource: [],
      selectedRowsData: [],
      list: [
        { value: '1', label: '最后联系', key: '1' },
        { value: '2', label: '名字A->Z', key: '2' },
        { value: '3', label: '最新添加', key: '3' },
        { value: '4', label: '联系频次', key: '4' },
      ],
    });
    const router = useRouter();
    const timer = ref(null);
    const startHandle = () => {
      state.visible = false;
      step.value = 1;
    };
    const stepChange = (type, temp) => {
      if (type == 'next') {
        step.value = temp + 1;
      } else if (type == 'prev') {
        step.value = temp - 1;
      } else if (type == 'done') {
        step.value = null;
        dashboardApi.updateUserStatus({
          id: store.state.account.user.id,
          status: 1,
        });
      }
      let obj = {
        temp: step.value,
        tempValue: step.value === null ? false : true,
      };
      proxy.$bus.emit('getTempTwo', obj);
    };
    dashboardApi
      .getUserStatus({
        id: store.state.account.user.id,
      })
      .then(res => {
        todayApi
          .getContactImportSituation('', {
            userId: store.state.account.user.id,
          })
          .then(result => {
            if (result) {
              // "Novice"新手 "incomplete"未完成    "untreated" 未处理  “complete”完成
              if (!res && result === 'complete') {
                state.loadingVisible = true;
                timer.value = setInterval(() => {
                  contactAll
                    .getPageList({
                      userId: store.state.account.user.id,
                      current: 1,
                      size: 10,
                      conditionDto: {
                        sortMap: { field: '', sort: 'asc' },
                        fieldList: [
                          'company',
                          'position',
                          'describe',
                          'frequency',
                          'last_interaction_time',
                          'socialize_url',
                        ],
                        conditionMap: [{ field: '', relation: '', value: '' }],
                      },
                    })
                    .then(data => {
                      if (data?.records.length > 0) {
                        gridRef.value.refreshGrid();
                        clearInterval(timer.value);
                        state.loadingVisible = false;
                        state.visible = true;
                      }
                    });
                }, 1000);
              }
            }
          });
      });

    contactAll.getTree('', { code: 'FREQUENCY' }).then(res => {
      if (res) {
        Object.assign(state.frequenctList, res);
      }
    });

    proxy.$bus.on('getTempTwo', value => {
      if (value) {
        router.push(`/contacts`);
      }
    });

    return {
      gridRef,
      step,
      userId,
      setAvatar,
      startHandle,
      stepChange,
      icons,
      ...toRefs(state),
      items: [{ key: 'name', label: '搜索姓名、公司、职位', type: 'input' }],
      columns: [
        {
          dataIndex: 'avatar',
          title: '头像',
          width: 60,
          ellipsis: true,
          slots: { customRender: 'avatar' },
        },
        {
          dataIndex: 'name',
          title: '姓名',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'name' },
        },
        {
          dataIndex: 'company',
          title: '公司',
          // width: 120,
          ellipsis: true,
          slots: { customRender: 'company' },
        },
        {
          dataIndex: 'position',
          title: '职位',
          // width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'describe',
          title: '备注',
          // width: 120,
          ellipsis: true,
        },
        // 频次
        {
          dataIndex: 'frequency',
          title: '联系频次',
          width: 120,
          // fixed: 'right',
          ellipsis: true,
          slots: { customRender: 'frequency' },
        },
        //   号码邮箱链接
        {
          dataIndex: 'infoList ',
          title: '联系方式',
          // fixed: 'right',
          width: 130,
          ellipsis: true,
          slots: { customRender: 'infoList' },
        },
        //   最后互动时间 last_interaction_time
        {
          dataIndex: 'lastInteractionTime',
          title: '最后互动时间',
          // width: 120,
          // fixed: 'right',
          // ellipsis: true,
          slots: { customRender: 'lastInteractionTime' },
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 60,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'selectAll',
          label: '跨页全选',
          permission: 'resources:selectAll',
          fnClick: () => {
            // console.log('全选', gridRef.value.getSelectedRowsKeys());
          },
        },
        {
          type: 'sort',
          label: '排序',
          permission: 'resources:sort',
          slots: { customRender: 'sort' },
          fnClick: () => {
            // console.log('排序排序', gridRef.value.getSelectedRowsKeys());
          },
        },
        {
          type: 'field',
          label: '字段',
          permission: 'resources:field',
          fnClick: () => {
            // console.log('字段');
          },
        },
        {
          type: 'sift',
          label: '筛选',
          permission: 'resources:sift',
          fnClick: () => {
            // console.log('筛选');
          },
        },
        //    有勾选数据情况下则展示下面这个几个操作按钮,不包含扩展符
        {
          type: 'contactFrequency',
          label: '联系频次',
          permission: 'resources:contactFrequency',
          fnClick: () => {
            // state.activeItemFrequency = gridRef.value.getSelectedRowsKeysAll();
            let parmas = {
              ...gridRef.value.getSelectedRowsKeysAll(),
              pageDTO: {
                conditionDto: state.parmas.conditionDto,
                name: state.name
              }
            }
            state.activeItemFrequency = parmas
            state.visibleFrequency = true;

          },
        },
        {
          type: 'addGroup',
          label: '添加到关系组',
          permission: 'resources:addGroup',
          fnClick: () => {

            let parmas = {
              ...gridRef.value.getSelectedRowsKeysAll(),
              pageDTO: {
                conditionDto: state.parmas.conditionDto,
                name: state.name
              }
            }
            state.activeItemAddGroup = parmas
            state.visibleAddGroup = true;
          },
        },
        {
          type: 'addPulse',
          label: '添加人脉圈',
          permission: 'resources:addPulse',
          fnClick: () => {
            state.visiblePluse = true;
            let parmas = {
              ...gridRef.value.getSelectedRowsKeysAll(),
              pageDTO: {
                conditionDto: state.parmas.conditionDto,
                name: state.name
              }
            }
            state.activeItemPluse = parmas
          },
        },
        {
          type: 'edit',
          label: '编辑',
          permission: 'resources:edit',
          fnClick: () => {
            state.visibleEdit = true;
            let parmas = {
              ...gridRef.value.getSelectedRowsKeysAll(),
              pageDTO: {
                conditionDto: state.parmas.conditionDto,
                name: state.name
              }
            }
            state.activeItemEdit = parmas
          },
        },
        {
          type: 'extend',
          label: '...',
          permission: 'resources:extend',
          // fnClick: () => {
          // console.log('扩展符');
          // },
        },
      ],
      options: [
        {
          type: 'detail',
          label: '详情',
          icon: 'EyeTwoTone',
          permission: 'distribution:detail',
          fnClick: record => {
            router.push(`/contacts/${record.id}`);
          },
        },
      ],
      url: contactAll.pageUrl,
      gridHeight: document.body.clientHeight - 400,
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },

      handleAdd: () => {
        state.visibleAddPage = true;
      },
      handleAddPage: value => {
        state.visibleAddPage = value;
        gridRef.value.refreshGrid();
      },

      handleGrid: parmas => {
        // grid传来的值
        let { value, type } = parmas;
        // console.log('grid传来的值', value, type);
        if (type === 'sort') {
          if (value === 'last_interaction_time' || value === 'create_time') {
            state.parmas.conditionDto.sortMap.sort = 'desc';
            gridRef.value.refreshGrid();
          } else {
            state.parmas.conditionDto.sortMap.sort = 'asc';
            gridRef.value.refreshGrid();
          }
          state.parmas.conditionDto.sortMap.field = value;
          gridRef.value.refreshGrid();
        } else if (type === 'field') {
          state.parmas.conditionDto.fieldList = value;
          gridRef.value.refreshGrid();
        } else if (type === 'sift') {
          // console.log('valuefgfd', value);
          if (value) {
            state.parmas.conditionDto.conditionMap = value;
            gridRef.value.refreshGrid();
          } else {
            state.parmas.conditionDto.conditionMap = [];
            gridRef.value.refreshGrid();
          }
        } else if (type === 'extend') {
          console.log('删除', value);

          // todo
          // 处理删除分页器的边界
          // selectAll 是否跨页全选， cancelRowsIdLength 取消勾选Id 数组长度, total 总数， id 勾选id 数组长度
          let delStu = isDelBoundary(value.selectAll, gridRef.value.getSelectedRowsKeysAll().id.length, gridRef.value.isTotal(), value.contactId.length)

          if (!delStu) {
            return message.error('请选择联系人');
          }

          // if (value.selectAll) {
          //   let total = gridRef.value.isTotal()
          //   let idLeng = gridRef.value.getSelectedRowsKeysAll().id.length
          //   if ((total - idLeng) === 0 ) {
          //     state.loading = false;
          //     return message.error('请选择联系人');
          //   }
          // }
          //
          // if (value.contactId.length === 0 && !value.selectAll) {
          //   state.loading = false;
          //   return message.error('请先选择联系人');
          // }

          Modal.confirm({
              title: `确认删除选中的联系人吗？`,
              icon: createVNode(icons['ExclamationCircleOutlined']),
              okType: '',
              okText: '确认',
              onOk: () => {
                let val = {
                  ...value,
                  pageDTO: {
                    conditionDto: state.parmas.conditionDto,
                    name: state.name
                  }
                }

                contactAll.deleteContactIds('', val).then(res => {
                  if (res) {
                    message.success('操作成功');
                    gridRef.value.refreshGrid(value.contactId.length);
                    state.loading = false;
                    // gridRef.value.refreshGrid();
                  }
                }).catch(() => {
                  message.error('网络异常，请联系技术人员处理')
                });;
              },
            });

        }
        // gridRef.value.refreshGrid();
      },
      getSearch: parmas => {
        console.log('parmas筛选', parmas )
        state.name = parmas.name

      },

      onSearch: () => {
        console.log(11);
      },
      handleGoToContact(record) {
        router.push(`/contacts/${record.id}`);
      },
      dataChange(data) {
        const { dataSource, type } = data;
        if (type === 'currentData') {
          state.dataSource = dataSource;
        } else if (type === 'selected') {
          // 勾选列表数据
          state.selectedRowKeys = dataSource;
        }
      },
      handleFrequencyPage(value) {
        state.visibleFrequency = value;
        // 刷新，去掉跨页全选
        gridRef.value.refreshGrid();
      },
      handleAddGroup(value) {
        state.visibleAddGroup = value;
        gridRef.value.refreshGrid();
      //   需要刷新关系组菜单  事件总线
        proxy.$bus.emit('shuaxinMenu', true);

      },
      handlePluse(value) {
        state.visiblePluse = value;
        gridRef.value.refreshGrid();
      },
      handleEditPage(value) {
        state.visibleEdit = value;
        gridRef.value.refreshGrid();
      },
      getEmail(item) {
        copyText(item.email);
        message.success('复制成功！');
      },
      getAllEmail(record) {
        // 过滤包含非空email字段的对象
        const emails = record
          .filter(
            item =>
              item.email !== null &&
              item.email !== undefined &&
              item.email !== ''
          )
          .map(item => item.email);
        const result = emails.length > 0 ? emails.join(',') : '';
        copyText(result);
        message.success('复制成功！');
      },

      getValue(item) {
        copyText(item.phone);
        message.success('复制成功！');
      },
      getAllValue(record) {
        const emails = record
          .filter(
            item =>
              item.phone !== null &&
              item.phone !== undefined &&
              item.phone !== ''
          )
          .map(item => item.phone);
        const result = emails.length > 0 ? emails.join(',') : '';
        copyText(result);
        message.success('复制成功！');
      },
      handleLine(link) {
        window.open(link, '_blank');
      },
      handleTime(record) {
        if (record.lastInteractionTime) {
          let [datePart] = record.lastInteractionTime?.split(' ');
          return datePart?.replace(/-/g, '/');
        } else {
          return '';
        }
      },
      handlePinCi(frequency) {
        if (frequency === '80') {
          return '无';
        }
        if (frequency === '10') {
          return '每周';
        }
        if (frequency === '20') {
          return '每月';
        }
        if (frequency === '30') {
          return '每季度';
        }
        if (frequency === '40') {
          return '每半年';
        }
        if (frequency === '50') {
          return '每年';
        }
        if (frequency === '60') {
          return '不要保持联系';
        }
      },
      handleOpenPinci(item) {
        // console.log('handleOpenPinci', item);
        state.visibleFrequency = true;
        state.activeItemFrequency = [item.id];
      },
    };
  },
};
</script>

<style lang="less" scoped>
.start-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.iss-main-grid {
  height: calc(100vh - 88px);
  //max-height: calc(100vh - 88px);
  overflow-y: auto;
  background: #fff;
  padding: 40px 72px;
}
.iss-main-grid /deep/ .ant-table-placeholder {
  border-bottom: none;
}
.contact-page {
  & .title {
    .right {
      i {
        margin-left: 3px;
      }
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contentOne {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  color: #333333;
}
.contentTwo {
  font-size: 12px;
  margin-top: 8px;
}
.contentAdd {
  //font-size: 14px;
  background: #ff7b00;
  padding: 8px 22px;
  color: #fff;
  border-radius: 4px;
  opacity: 1;
  cursor: pointer;
}
.headerUrl {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

//.gridOptions {
//  display: flex;
//  justify-content: space-between;
//  align-items: center;
//  margin-top: 48px;
//  .selectAll {
//    cursor: pointer;
//  }
//  .funOperation {
//    display: flex;
//    justify-content: space-between;
//    align-items: center;
//    cursor: pointer;
//    //width: 30%;
//    .one {
//      display: flex;
//      align-items: center;
//      .gridHeader {
//        .gridHeader-img {
//          margin-right: 2px;
//        }
//      }
//      .sift {
//        margin: 0px 8px;
//        .sift-img {
//          margin-right: 2px;
//        }
//      }
//    }
//    .two {
//      display: flex;
//      align-items: center;
//      .group {
//        margin: 0px 8px;
//        .group-img {
//          margin-right: 2px;
//        }
//      }
//      .contact-img {
//        margin-right: 2px;
//      }
//      .edit {
//        margin-right: 8px;
//        .edit-img {
//          margin-right: 2px;
//        }
//      }
//    }
//    .export {
//      margin-right: 8px;
//    }
//  }
//}
.menuClass {
  display: flex;
  justify-content: space-between;
  margin: 12px;

  .menuText {
    margin-left: 24px;
    cursor: pointer;
  }
}

.allValue {
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  border-top: 0.5px solid #eeeeee;
}
.email {
  margin-right: 12px;
  cursor: pointer;
}

//.showEmail {
//  display: none;
//}
.recordContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.wei-img,
.zhi-img,
.dou-img {
  margin-right: 12px;
  cursor: pointer;
}
.dropdownCalss {
  min-width: 203px;
  min-height: 78px;
}
.nonePhone {
  display: none;
}
.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  img {
    width: 300px;
  }
  .loading {
    display: inline-block;
    text-align: center;
    color: @primary-color;
    font-size: 24px;
    margin-top: 30px;
    width: 220px;
    &:after {
      overflow: hidden;
      display: inline-block;
      padding-left: 6px;
      vertical-align: bottom;
      animation: ellipsis steps(9, end) 1200ms infinite;
      content: '\2026';
      width: 0px;
    }
  }
}
.frequencyImage {
  width: 18px;
  height: 18px;
}

.frequenct-item {
  width: auto;
  padding: 4px 10px;
  box-sizing: border-box;
  display: inline-block;
  border: 1px solid #eeeff5;
  border-radius: 4px;
  background: #fff;

  span {
    color: #6b7592;
    font-size: 12px;
    font-weight: 400;
  }
}

.contact-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
}
</style>
